import React, { Component } from 'react';
import style from './scaffold.module.css';

export default class MiddlePanel extends Component {
    render() {
        return (
            <div className={style.middlePanel} />
        );
    }
}
