export const listOfActions = {

    login: {
        LOGIN_REQUESTED: 'LOGIN_REQUESTED',
        LOGIN_SUCCESS: 'LOGIN_SUCCESS',
        LOGIN_FAILURE: 'LOGIN_FAILURE',
        CHECKING_LOGIN: 'CHECKING_LOGIN',
        NOT_LOGGED_IN: 'NOT_LOGGED_IN',
        LOGGED_IN: 'LOGGED_IN',
        LOGOUT_REQUEST: 'LOGOUT_REQUEST'
    },
    searchPatient: {
        SEARCH_PATIENTS_BY_ID_REQUEST: 'SEARCH_PATIENTS_BY_ID_REQUEST',
        SEARCH_PATIENTS_BY_ID_SUCCESS: 'SEARCH_PATIENTS_BY_ID_SUCCESS',
        SEARCH_PATIENTS_BY_ID_FAILURE: 'SEARCH_PATIENTS_BY_ID_FAILURE',
        SEARCH_PATIENTS_BY_ID_CLEAR: 'SEARCH_PATIENTS_BY_ID_CLEAR'
    },
    searchMedDRA: {
        SEARCH_MEDDRA_REQUEST: 'SEARCH_MEDDRA_REQUEST',
        SEARCH_MEDDRA_SUCCESS: 'SEARCH_MEDDRA_SUCCESS',
        SEARCH_MEDDRA_FAILURE: 'SEARCH_MEDDRA_FAILURE'
    },
    getPatientProfileById: {
        GET_PATIENT_PROFILE_BY_ID_REQUEST: 'GET_PATIENT_PROFILE_BY_ID_REQUEST',
        GET_PATIENT_PROFILE_BY_ID_FAILURE: 'GET_PATIENT_PROFILE_BY_ID_FAILURE',
        GET_PATIENT_PROFILE_BY_ID_SUCCESS: 'GET_PATIENT_PROFILE_BY_ID_SUCCESS'
    },
    createPatients: {
        CREATE_PATIENT_REQUEST: 'CREATE_PATIENT_REQUEST',
        CREATE_PATIENT_SUCCESS: 'CREATE_PATIENT_SUCCESS'
    },
    patientProfile: {
        HISTORY_FILTER: 'HISTORY_FILTER',
        PII_REQUEST: 'PII_REQUEST',
        PII_RECEIVED: 'PII_RECEIVED'
    },
    visits: {
        CREATE_VISIT_REQUEST: 'CREATE_VISIT_REQUEST',
        CREATE_VISIT_SUCCESS: 'CREATE_VISIT_SUCCESS'
    },
    tests: {
        CREATE_TEST_REQUEST: 'CREATE_TEST_REQUEST',
        CREATE_TEST_SUCCESS: 'CREATE_TEST_SUCCESS'
    },
    treatments: {
        CREATE_TREATMENT_REQUEST: 'CREATE_TREATMENT_REQUEST',
        CREATE_TREATMENT_SUCCESS: 'CREATE_TREATMENT_SUCCESS'
    },
    clinicalEvents: {
        CREATE_CE_REQUEST: 'CREATE_CE_REQUEST',
        CREATE_CE_SUCCESS: 'CREATE_CE_SUCCESS'
    },
    data: {
        ALTER_DATA_REQUEST: 'ALTER_DATA_REQUEST',
        ALTER_DATA_SUCCESS: 'ALTER_DATA_SUCCESS',
        ALTER_DATA_FAILURE: 'ALTER_DATA_FAILURE'
    },
    availableFields: {
        GET_DEMO_FIELDS_SUCCESS: 'GET_DEMO_FIELDS_SUCCESS',
        GET_VISIT_FIELDS_SUCCESS: 'GET_VISIT_FIELDS_SUCCESS',
        GET_TEST_FIELDS_SUCCESS: 'GET_TEST_FIELDS_SUCCESS',
        GET_CE_TYPES_SUCCESS: 'GET_CE_TYPES_SUCCESS',
        GET_CE_FIELDS_SUCCESS: 'GET_CE_FIELDS_SUCCESS',
        GET_TEST_TYPES_SUCCESS: 'GET_TEST_TYPES_SUCCESS',
        GET_DRUGS_SUCCESS: 'GET_DRUGS_SUCCESS',
        GET_INPUT_TYPES_SUCCESS: 'GET_INPUT_TYPES_SUCCESS',
        GET_RELATIONS_SUCCESS: 'GET_RELATIONS_SUCCESS',
        GET_DIAGNOSES_SUCCESS: 'GET_DIAGNOSES_SUCCESS',
        GET_PREGNANCY_OUTCOMES_SUCCESS: 'GET_PREGNANCY_OUTCOMES_SUCCESS',
        //
        GET_PREGNANCY_ENTRY_TYPES_SUCCESS: 'GET_PREGNANCY_ENTRY_TYPES_SUCCESS',
        GET_PREGNANCY_ENTRY_FIELDS_SUCCESS: 'GET_PREGNANCY_ENTRY_FIELDS_SUCCESS',
        GET_PREGNANCY_ENTRY_SUCCESS: 'GET_PREGNANCY_ENTRY_SUCCESS',
        GET_MEDDRA_SUCESS: 'GET_MEDDRA_SUCESS',
        GET_MEDDRA_TREE_SUCESS: 'GET_MEDDRA_TREE_SUCESS',
        GET_INTERRUPTION_REASONS_SUCESS: 'GET_INTERRUPTION_REASONS_SUCESS',
        GET_VISIT_SECTIONS_SUCCESS: 'GET_VISIT_SECTIONS_SUCCESS',
        GET_ICD11_SUCCESS: 'GET_ICD11_SUCCESS',
        GET_ICD11_TREE_SUCESS: 'GET_ICD11_TREE_SUCESS',
        GET_CONCOMITANT_MEDS_SUCCESS: 'GET_CONCOMITANT_MEDS_SUCCESS'
    },
    admin: {
        GET_LOG_REQUEST: 'GET_LOG_REQUEST',
        GET_LOG_SUCCESS: 'GET_LOG_SUCCESS',
        GET_LOG_FAILURE: 'GET_LOG_FAILURE',
        GET_ALL_USERS_REQUEST: 'GET_ALL_USERS_REQUEST',
        GET_ALL_USERS_SUCCESS: 'GET_ALL_USERS_SUCCESS',
        GET_ALL_USERS_FAILURE: 'GET_ALL_USERS_FAILURE',
        CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
        UPLOAD_MEDDRA_REQUEST: 'UPLOAD_MEDDRA_REQUEST',
        UPLOAD_MEDDRA_SUCCESS: 'UPLOAD_MEDDRA_SUCCESS',
        UPLOAD_MEDDRA_FAILURE: 'UPLOAD_MEDDRA_FAILURE'
    },
    erasePatient: {
        ERASE_PATIENT_REQUEST: 'ERASE_PATIENT_REQUEST',
        ERASE_PATIENT_SUCCESS: 'ERASE_PATIENT_SUCCESS',
        ERASE_PATIENT_FAILTURE: 'ERASE_PATIENT_FAILURE',
        ERASE_PATIENT_RESET: 'ERASE_PATIENT_RESET'
    },
    appLevelError: {
        CLEAR_ERROR: 'CLEAR_ERROR',
        ADD_ERROR: 'ADD_ERROR'
    },
    alert: {
        CLEAR_ALERT: 'CLEAR_ALERT',
        ADD_ALERT: 'ADD_ALERT'
    },
    edssCalc: {
        CLEAR_CALCULATOR: 'CLEAR_CALCULATOR',
        DISPLAY_CALCULATOR: 'DISPLAY_CALCULATOR'
    },
    serverInfo: {
        GET_SERVER_INFO_SUCCESS: 'GET_SERVER_INFO_SUCCESS'
    },
    syncInfo: {
        GET_SYNC_OPTIONS_SUCCESS: 'GET_SYNC_OPTIONS_SUCCESS',
        SET_SYNC_OPTIONS_SUCCESS: 'SET_SYNC_OPTIONS_SUCCESS',
        GET_SYNC_STATUS_SUCCESS: 'GET_SYNC_STATUS_SUCCESS',
        SYNC_TRIGGER_SUCCESS: 'SYNC_TRIGGER_SUCCESS'
    },
    visitFrontPage: {
        CLEAR_BASELINE_VISIT_FRONTPAGE: 'CLEAR_BASELINE_VISIT_FRONTPAGE',
        DISPLAY_BASELINE_VISIT_FRONTPAGE: 'DISPLAY_BASELINE_VISIT_FRONTPAGE',
        CLEAR_FOLLOWUP_VISIT_FRONTPAGE: 'CLEAR_FOLLOWUP_VISIT_FRONTPAGE',
        DISPLAY_FOLLOWUP_VISIT_FRONTPAGE: 'DISPLAY_FOLLOWUP_VISIT_FRONTPAGE'
    }
};

export default listOfActions;